import Button from "../../components/Button/Button";
import ButtonsWrapper from "../../components/ButtonsWrapper/ButtonsWrapper";
import {useMutation} from "@tanstack/react-query";
import axios, {AxiosError} from "axios";
import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import ErrorNotification from "../../components/ErrorNotification/ErrorNotification";
import LoadingOverlayWrapper from "react-loading-overlay-ts";

type IEncodeUrls = {
    data: {
        completed: string;
        screenout: string;
        quotafull: string;
        qual: string;
    }
}

const MainView = () => {
    const {mutate, isLoading, data, error} = useMutation<IEncodeUrls, AxiosError, string>((idToQuery) => {
        return axios.get(`https://api.npb.com.pl/api/panelists/${idToQuery}/seba_get`);
    });

    const [searchParams] = useSearchParams();
    searchParams.get("id")

    const idToQuery = searchParams.get("id")

    useEffect(() => {
        if(!isLoading && !data && !error && idToQuery) {
            mutate(idToQuery)
        }
    }, [mutate, isLoading, data, error, idToQuery])

    if(!idToQuery) {
        return <ErrorNotification notificationText="Brak parametru ?id= w adresie URL" />
    }

    if (isLoading) return <LoadingOverlayWrapper
        styles={{
            wrapper: {
                width: '100%',
                height: '100%',
            }
        }}
        active={true}
        spinner
        text='Ładowanie...'
    />;

    if (error) return <ErrorNotification notificationText={'Błąd: ' + error.message}></ErrorNotification>;

    if (data) return (
        <ButtonsWrapper>
            <Button buttonText="Completed" buttonUrl={`https://aplikacja.npb.com.pl/panelista/questionnaire-result?token=${data.data.completed}`} />
            <Button buttonText="Screen Out" buttonUrl={`https://aplikacja.npb.com.pl/panelista/questionnaire-result?token=${data.data.screenout}`} />
            <Button buttonText="Quota Full" buttonUrl={`https://aplikacja.npb.com.pl/panelista/questionnaire-result?token=${data.data.quotafull}`}/>
            <Button buttonText="Qual" buttonUrl={`https://aplikacja.npb.com.pl/panelista/questionnaire-result?token=${data.data.qual}`}/>
        </ButtonsWrapper>
    )

    return <ErrorNotification notificationText="Wystąpił nieznany błąd" />
}

export default MainView