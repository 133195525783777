import React from "react"

import { useRoutes } from "react-router-dom"
import MainView from "../views/MainView";

export default function DashboardRoutes() {
    return useRoutes([
        {
            path: "/*",
            children: [
                {
                    path: "*",
                    element: <MainView />,
                },
            ],
        },
    ])
}
